import Image from "next/image";
import LinkWrapper from "../LinkWrapper";
import FaArrowRight from "../SVG/Fontawesome/FaArrowRight";
import { useState } from "react";
import FaChevronDown from "../SVG/Fontawesome/FaChevronDown";
import Shimmer from "@/utils/shimmer";

const TopCardItem = (props: {
  title?: string;
  subtitle?: string;
  content?: string;
  imgUrl: string;
  alt?: string;
  href?: string;
  className?: string;
}) => {
  return (
    <div className={` w-full h-full flex flex-col justify-between items-center hover:bg-secondary ${props.className || ""}`}>
      <div className="flex flex-col justify-center items-center gap-4">
        {props.title && <div className="text-3xl font-medium">{props.title}</div>}
        <Image
          src={props.imgUrl}
          width={182}
          height={182}
          alt={props.alt ?? props.title ?? props.subtitle ?? ""}
          // TODO can add this sm:w-full
          className="card   m-0"
          placeholder="blur"
          blurDataURL={Shimmer(182, 182)}
        />
        <div>
          {props.subtitle && <div className="text-lg font-bold">{props.subtitle}</div>}
          {props.content && <p className="text-base mt-2">{props.content}</p>}
        </div>
      </div>
      {props.href && <button className="btn-tertiary flex items-center gap-2">Explore Now <FaArrowRight className="fill-accent" /></button>}
    </div>
  );
}

const TopCards = (props: {
  title?: string;
  items: {
    title?: string;
    subtitle?: string;
    content?: string;
    imgUrl: string;
    alt?: string;
    href?: string;
  }[];
  maxCount?: number;
}) => {
  const [visibleCount, setVisibleCount] = useState<number>(props.maxCount || props.items.length);

  if (!props.items?.length) return null;
  return (
    <section className="text-center mb-12">
      <div className="flex flex-col">
        {props.title && <h2>{props.title}</h2>}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {
            props.items.slice(0, visibleCount).map((item, index) => {
              if (item.href) {
                return <LinkWrapper key={index.toString()} href={item.href} className="!no-underline hover-pop">
                  <TopCardItem key={index.toString()} {...item} />
                </LinkWrapper>
              }
              return <TopCardItem key={index.toString()} {...item} />
            })
          }
        </div>
        {
          (visibleCount < props.items.length) && <button className="btn-tertiary mt-4" onClick={() => setVisibleCount(props.items.length)}>
            View All <FaChevronDown />
          </button>
        }
      </div>
    </section>
  );
}

export default TopCards;